@mixin mod-card-bianca-banner() {
    // Start banner round
    .video-rectangle{
        position: relative;
        z-index: 1;
        color: $color-alleanza-blue;
        margin-top: -100px;
        justify-content: center;
        .rectangle-white{
            z-index: 1;
            margin-left: auto;
            margin-right: auto;
            width: 996px;
            min-height: 300px;
            height: auto;
            object-fit: contain;
            border-radius: 8px;
            box-shadow: $shadow-style;
            background-color: $white;
            display: flex;
            .col-video{
                // align-self: center;
                margin-top: 20px;
            }
            .video-banner{
                object-fit: contain;
                width: 364px;
                height: 260px;
                .youtubePlayer{
                    border-radius: 4px;
                }
            }
            .col-agente{
                padding-left: 0;
            }
        }
        .virgolette{
            font-size: 39px;
        }
        .piano{
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            margin-top: 10px;
            line-height: 1.24;
            padding-bottom: 10px;
            margin-top: 0;
        }
        .agente{
            margin-top: 21px;
            display: flex;
            .agente-desc{
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                font-family: 'AlleanzaSans', 'Arial', sans-serif;
            }
            .agente-name{
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color:$color-names-orange;
                text-decoration: underline;
                padding-left: 5px;
            }
        }
        .role{
            margin-top: 11px;
            line-height: 1.43;
            color: $dark-grey-2 !important;
            margin-bottom: 20px;

        }
        .rectangle-white-static{
            z-index: 1;
            width: 792px;
            min-height: 220px;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
            border-radius: 8px;
            box-shadow: $shadow-style;
            background-color: $white;
            display: flex;
            .col-foto {
                .foto{
                    background-size: cover;
                    margin-top: 20px;
                    width: 64px;
                    height: 64px;
                    border-radius: 58.7px;
                }

                .agente{
                    margin-top: 10px;
                    display: flex;
                    .agente-desc{
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 700;
                        font-family: 'AlleanzaSans', 'Arial', sans-serif;
                    }
                    .agente-name{
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                        color:$color-names-orange;
                        text-decoration: underline;
                        padding-left: 5px;
                    }
                }

                .role{
                    margin-top: 6px;
                    line-height: 1.43;
                    color: $dark-grey-2 !important;
                    font-family: 'AlleanzaSans', 'Arial', sans-serif;
                    width: 70%;
                }
            }

            .col-agente {
                padding: 10px;

                .piano{
                    font-size: 24px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.24;
                    letter-spacing: normal;
                    padding-bottom: 20px;
                }
            }
        }
    }
    // End banner round

    // Start banner secondario
    .box-white{
        position: relative;
        z-index: 10;
        color: $color-alleanza-blue;
        margin-top: -100px;
        .rectangle-small{
            margin-left: 120px;
            z-index: 1;
            width: 588px;
            height: auto;
            min-height: 198px;
            object-fit: cover;
            border-radius: 8px;
            box-shadow: $shadow-style;
            background-color: $white;
            display: flex;
            .font-serif-display-5{
                font-size: 12px;
                line-height: 18px;
            }
        }
        .col-foto, .col-agente{
            margin-top: 10px;
        }
        .foto{
            background-size: cover;
            width: 42px;
            height: 42px;
            border-radius: 21px;
        }
        .agente-box{
            margin-left: 0;
            margin-top: 10px;
            width: 100%;
            div{
                font-size: 14px;
                font-family: 'AlleanzaSans', 'Arial', sans-serif;
                font-weight: 600;
            }
            .agente-name{
                color:$color-names-orange;
                text-decoration: underline;
                padding-left: 5px;
                font-size: 14px;
            }
            .role{
                margin-top: 4px;
                font-weight: 400;
                color: $dark-grey-2 !important;
            }
        }
        .virgolette{
            font-size: 26px;
        }
        .piano{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    // End banner secondario
}

@mixin mod-card-bianca-banner-desktop() {
    // Start banner round
    .video-rectangle{
        .video-banner{
            object-fit: contain;
            width: 274px;
            height: 196px;
            .youtubePlayer{
                border-radius: 4px;
            }
        }
    }
    // End banner round

    // Start banner secondario
    .box-white{
        color: $color-alleanza-blue;
        display: flex;
        justify-content: flex-end;
        max-width: 51.5%; 
        
        .rectangle-small{
            margin-right: 30px; 
            z-index: 1;
            width: 588px; 
            min-height: 174px;
            object-fit: cover;
            border-radius: 8px;
            box-shadow: $shadow-style;
            background-color: $white;
            display: flex;
        }

        @media (min-width: 1900px) { //fix over 1440 - 1700
            .rectangle-small {
                margin-right: 38px; 
                max-width: 695px; 
            }
        }

        @media (min-width: 2500px) { //fix over 1440 - 1700
            .rectangle-small {
                margin-right: 48px; 
                max-width: 695px; 
            }
        }

        @media (min-width: 3000px) { //fix over 1440 - 1700
            .rectangle-small {
                margin-right: 58px; 
                max-width: 695px; 
            }
        }

        .col-foto, .col-agente{
            margin-top: 20px;
        }
        .foto{
            background-size: cover;
            width: 42px;
            height: 42px;
            border-radius: 21px;
        }
        .agente-box{
            margin-left: 0;
            margin-top: 10px;
            .agente-name{
                color:$color-names-orange;
                text-decoration: underline;
                padding-left: 5px;
            }
        }
        .piano{
            margin-top: 10px;
        }
    }
    
    // End banner secondario

}

@mixin mod-card-bianca-banner-tablet-landscape(){
    // Start banner round
    .video-rectangle{
        .rectangle-white{
            width: 772px;
            min-height: auto;

            .col-video{
                // align-self: center;
                margin-top: 20px;
            }
            .video-banner{
                object-fit: contain;
                width: 274px;
                height: 196px;
                .youtubePlayer{
                    border-radius: 4px;
                }
            }
            .kalturaPlayer{
                width: 274px;
                height: 196px;
                border-radius: 4px;
    
            }
            .col-agente{
                padding-left: 0;
                .agente{
                    margin-top: 20px;
                    display: flex;
                    .agente-desc{
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .agente-name{
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                .piano{
                    font-size: 24px;
                    line-height: 1.25;
                    margin-bottom: 20px;
                    padding-bottom: 0px;
                }
            }
        }

        .rectangle-white-static{
            width: 772px;

            .col-agente {
                .virgolette {
                    margin-top: 20px;
                }
            }
        }
    }
    // End banner round

    // Start banner secondario
    .box-white{
        .agente-box{
            flex-wrap: wrap;
            div {
                padding-right: 5px;
            }
        }
        .rectangle-small{
            margin-left: 30px;
            width: 45.5%; //50%;
            min-height: 198px;
        }
    }
    // end banner secondario

}
@mixin mod-card-bianca-banner-tablet() {
    // Start banner round
    .video-rectangle{
        .rectangle-white{
            width: 588px;
            min-height: auto;
            height: auto;
            border-radius: 8px;
            box-shadow: $shadow-style;
            .col-video {
                margin-top: 10px !important;
                .video-banner{
                    width: 218px;
                    height: 156px;
                    .youtubePlayer{
                        border-radius: 2.4px;
                    }
                }
            }
     
            .col-agente{
                .agente {
                    margin-top: 11px;
                }
                .role{
                    line-height: 1.43;
                }
                .piano{
                    font-size: 18px;
                    line-height: 1.33;
                    margin-bottom: 20px;
                    padding-bottom: 0px;
                }
            }
        }
        .rectangle-white-static{
            width: 588px;
            min-height: 208px;
            height: auto;
            .col-foto {
                .foto {
                    margin-top: 10px;
                }
                .role {
                    margin-top: 6px;
                    width: 100%;
                }
            }
            .col-agente{
                padding-top: 10px;
            }
            .virgolette {
                margin-top: 10px !important;
            }
            .piano{
                font-size: 18px !important;
                line-height: 1.33 !important;
            }
            
        }
    }
    // End banner round

    // Start banner secondario
    .box-white{
        margin-top: -80px;
        .rectangle-small{
            width: 45%;//50%
            margin-left: 30px;
            min-height: 236px;
            .col-agente{
                margin-top: 10px;
            }
            .foto{
                width: 48px;
                height: 48px;
                border-radius: 58.7px;
                margin-top: 10px;
            }
            .agente-box{
                margin-left: 10px;
            }
            .col-foto{
                margin-top: 10px;
                display: flex;
            }
            .piano{
                width: 308px;
                margin-top: 10px;
                font-size: 18px;
                line-height: 1.27;
            }
        }
    }
    
    // End banner secondario

}

@mixin mod-card-bianca-banner-mobile() {
    // Start banner round
    .video-rectangle{
        margin-top: -100px;
        padding: 0;
        .rectangle-white{
            width: 100%;
            min-height: 450px;
            height: auto;
            border-radius: 8px;
            box-shadow: $shadow-style;
            .col-video{
                align-self: normal;
                margin-top: 20px;
            }
            .video-banner{
                width: 100%;
                height: 205px;
                .youtubePlayer{
                    border-radius: 4px;
                }
                background-color: rgba(0, 15, 32, 0.3);
            }
            .piano{
                font-size: 18px;
                // width: 305px;
                margin-bottom: 20px;
                padding-bottom: 0px;
            }
            .col-agente{
                padding-left: 15px;
                margin-top: 10px;
                -ms-transform: translate(-110%, 0%);
                z-index: 9;
                .role{
                    margin-right: 0;
                }
                .agente{
                    margin-right: 0;
                    margin-top: 0;
                }
            }
        }
        .rectangle-white-static{
            width: 100%;
            min-height: auto;
            height: auto;
            object-fit: contain;
            border-radius: 8px;
            box-shadow: $shadow-style;
            .col-agente{
                padding: 0 15px 0 15px;
            }
            .col-foto{
                width: 100%;
                padding-right: 0;
                z-index: 6;
                margin-left: 0px;
                
                .foto{
                    margin-right: 0;
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    border-radius: 58.7px;
                }
                .agente{
                    margin-right: 0;
                    // width: 231px;
                    margin-left: 80px;
                    margin-top: 20px !important;
                }

                .role {
                    margin-left: 80px;
                    margin-top: 10px;
                    width: 70%;
                }
            }
            .piano{
                font-size: 18px !important;
                line-height: 24px !important;
                width: 100%;
                padding-bottom: 20px;
                margin-top: 0;
            }
            .virgolette{
                margin-top: 10px !important;
            }
        }
    }
    // End banner round

    // Start banner secondario
    .box-white{
        margin-top: -100px;
        .rectangle-small{
            margin-left:16px;
            width: 91%; //345px;
            min-height: 236px;
            .col-agente{
                margin-top: 10px;
                padding-left: 20px;
            }
            .foto{
                width: 48px;
                height: 48px;
                border-radius: 58.7px;
                margin-top: 10px;
            }
            .agente-box{
                margin-left: 10px;
                -ms-transform: translateX(-31.5%); 
                .role{      
                    -ms-transform: translateX(-20%);
                }       
            }
            .col-foto{
                display: flex;
                margin-top: 10px;
                width: 305px;
                padding-left:20px;
            }
            .col-agente {
                width: 94%;
            }

            .piano{
                font-size: 18px;
                line-height: 1.33;
            }
        }
    }
    
    // End banner secondario

}