@mixin mod-banner-agenzie() {
    .banner-agenzie-homepage{

        .map-tablet{
            display:none;
        }

        .input-container{
            margin-bottom: 24px;
        }

        .testo-header{
            width: 125px;
            height: 18px;
            flex-grow: 0;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color:#002855;
            position:absolute;
        }

        .alleanza-input--outlined{
            padding: 0 16px;
            border-radius: 4px;
            border: solid 1px #9b9b9b;
            background-color: #fff;
            &:focus{
                border-radius: 4px;
                border: solid 1px #20252b;
                background-color: #fff;
            }
        }

        .alleanza-input-container .banner-agenzie-input .clear-link {
            top: 0px;
            display: none;
            z-index: 2;
        }

        .banner-agenzie-input .input-container{
            height:100%;
        }

        .banner-agenzie-input input {
            position: relative;
            background: transparent;
            z-index: 1;
        }

        .banner-agenzie-input {
            border: none;
            padding: 0;
        }

        .banner-agenzie-input .clear-link {
            position: absolute;
            right: 10px;
        }

        .alleanza-input-container:has(.banner-agenzie-input input:focus) {
            outline: none;
            outline-offset:0;
            border-radius:0;
        }

        .input-container input:focus{
            outline: 2px solid #00a980;
            outline-offset:2px;
            border-radius:6px;
            border: 2px solid #002855;
            border-color:#002855;
        }

        .input-container input{
            border: 1px solid #002855;
            border-color:#002855;
        }

        .banner-agenzie-input .label {
            position: absolute;
            top: 11px;
            padding-left: 10px;
            color: #6e757c;
            font-size: 16px;
        }

        .alleanza-input-container .alleanza-input .input-container .clear-link {
            top: 0px;
        }
        .alleanza-input-container{
            margin-top: 24px;
            margin-bottom: 24px;
            border: 2px solid transparent;
            // padding: 2px;
        }
        .alleanza-input-container .banner-agenzie-input input {
            font-size: 16px;
            padding-top: 0px !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .align-middle{
            vertical-align:middle;
            display:flex;
            margin-top:auto;
            margin-bottom:auto;
            margin-left:25px;
        }



        .alleanza-input-container:has(input:focus) {
            outline: 2px solid #00779a;
            outline-offset:2px;
            border-radius:6px;
        }

        .alleanza-input--outlined:has(input:focus){
            border-radius: 4px;
            border: solid 1px #20252b;
            background-color: #fff;
        }

        // .generali-input-container .generali-input .input-container input:focus{
        //     border: 1px solid #00779a;
        //     border-radius:4px;
        //     padding:24px;
        //     top:-10px;
        // }
        
        button:focus{
            outline: 2px solid #00779a;
            outline-offset:2px;
            border-radius:8px;
        }

        .alignment-text{
            font-size: 24px;
            font-weight: bold;
            color:#002855;
            line-height: 1.25;
        }
        .image-banner-agenzie{
            // margin-top: 40px;
            // width:100%;
            height:402px;
            object-fit: cover;
            background-position: center;
            background-size: cover;
            border-radius:16px;
        }

        .box-style{
            width:100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            // margin: 65px 600px 64px 0;
            // margin-top:-330px;
            border-radius: 12px;
            padding: 48px 48px;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
            background-color: #fff; 
            z-index:1;
            max-height: 322px;
        }
        .alleanza-input--focused{
            .input-container{
                .label{
                    top: -5px;
                }
            }
        }
        .button-cerca{
            width: 100%;
            height: 48px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            // padding: 12px 114px;
            border:none;
            border-radius: 8px;
            background-color: #ec6608;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            &.disabled{
                background-color: #8c8c8c;
                color: #cacaca;
                border:none;

            }

        }


        .icon-alleanza-clear{
            top: 9px;
            position: relative;
            cursor:pointer;
        }

    }
.banner-agenzie-homepage .alleanza-input-container .alleanza-input .input-container,
.banner-agenzie-homepage .alleanza-input-container .alleanza-input {
	padding: 0px;
}

.banner-agenzie-homepage .alleanza-input-container .alleanza-input .input-container input {
	padding: 0 25px 0 14px;
}

.banner-agenzie-homepage .alleanza-input-container .alleanza-input .input-container .label {
	align-items: center;
	display: flex;
	height: 100%;
	padding-left: 16px;
}

.banner-agenzie-homepage .alleanza-input-container .alleanza-input.alleanza-input--focused .input-container .label {
	height: auto;
}

 .pac-container {
    margin-top: 10px;
    border: 1px solid #002855;
    border-radius: 4px;
    /* color: blue; */
    padding: 4px;
}

.pac-item:hover {
    background-color: #f2f2f2;
}

 .pac-container::after {
	display: none;
}

.pac-container .pac-item {
    height: 60px;
    line-height: 60px;
    border-bottom: unset;
    border-top: none;
    color: #303a45;
    /* padding-left: 35px; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    font-size: 14px;
    font-family: inherit;
    /* background-image: url(../../images/flag-outline.svg); */
    background-repeat: no-repeat;
    // background-position: 1px 18px;
    border-radius: 4px;
    cursor:pointer;
    border-bottom: 1px solid #cacaca;
}

.pac-container .pac-item .pac-icon {
    width: 24px !important;
    height: 24px !important;
    margin-right: 9px !important;
    margin-left: 8px !important;
    margin-top: 0;
    /* margin-left: 40px; */
    vertical-align: middle;
    background-size: 24px !important;
    display:inline-block !important;
    background-image: url(../../images/flag-outline.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 0px !important;
}

.pac-container .pac-item .pac-item-query {
	color: #20252b;
}

.pac-container .pac-item .pac-item-query.pac-matched {
	font-family: inherit;
	font-weight: bold;
}

.pac-container .pac-item .pac-icon {
	width: 15px;
	height: 20px;
	margin-right: 7px;
	margin-top: 0px;
	margin-left: 40px;
	vertical-align: middle;
	background-size: 34px;
	display: none;
}

.pac-container .pac-item span {
	font-family: inherit;
}

}


@mixin mod-banner-agenzie-desktop(){
    .banner-agenzie-homepage{
        .map-tablet{
            display:none;
        }
    }

}

@mixin mod-banner-agenzie-tablet-landscape() {
    .banner-agenzie-homepage{
        .box-style{
            max-height: 352px;
            min-width: 385px;
        }
        .map-tablet{
            display:none;
        }
    }


}
@mixin mod-banner-agenzie-tablet-portrait() {
    .banner-agenzie-homepage{
        .align-middle{
            margin-left: unset;
        }
        .container{
            padding-left: 30px;
            padding-right: 30px;;
        }

        .map-desktop{
            display:none;
        }

        .map-tablet{
            display: inline-block;
        }

        .button-cerca{
            position:relative;
            margin-top: 20px;
        }

        .box-style{
             padding:48px;
             margin-top:40px;
             max-height: 250px;
        }

        .alignment-text{
            max-width: 500px;
        }

        .image-banner-agenzie{
            // margin-top: 40px;
            // width:100%;
            height: 660px;
            background-position: left;
            width:100%;
        }
        
    }


}
@mixin mod-banner-agenzie-mobile() {
    .banner-agenzie-homepage{
        .map-desktop{
            display:none;
        }

        .map-tablet{
            display: inline-block;
        }
        .alleanza-input-container{
            margin-top: 24px;
            margin-bottom:24px;
        }
        .alignment-text{
            font-size: 20px;
            max-width: 282px;
        }
        .container{
            padding-left:16px;
            padding-right: 16px;
        }
        .image-banner-agenzie{
            // margin-top: 40px;
            // width:100%;
            height: 536px;
            background-position: center;
            width:100%;
        }
         .box-style{
            margin-top:16px;
            // max-width:345px;
            height:auto;
            max-height: 280px;
            // margin-left:15px;
            // margin-right:15px;
            padding:32px 16px;
        }
        .align-middle{
            margin-top:auto;
            margin-bottom:auto;
            margin-left: unset;
        }
    }
 
}