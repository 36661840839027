@mixin mod-banner-boxed() {
    .banner-boxed-xs{
        &-container{
            border-radius: 24px;
            min-height: 250px;
            @include colors-banner-boxed-xs();
        }
        img{    
            width: 100%;
            height: 100%;
            min-height: 250px;
            object-fit: cover;
        }
    }
    .banner-hero-boxed{
        width: 930px;
        object-fit: contain;
        border-radius: 24px;
        // margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        .image-mobile{
            display: none;
        }
        &.large{
            height: 600px;
            .col-mask{
                height: 600px;
                padding: 0;
            }
            .image{
                width: 100%;
                height: 100%;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                object-fit: cover;
            }
        }

        &.medium{
            height: 400px;
            .col-mask{
                height: 400px;
                padding: 0;
            }
            .image{
                width: 100%;
                height: 100%;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                object-fit: cover;
            }
        }
        .objects{
            padding-left: 30px;
            align-self: center;
            width: 612px;
        }
        .agente{
            margin-top: 20px;
            display: flex;
            &-told{
                font-weight: bold;
            }
            &-photo{
                background-size: cover;
                width: 48px;
                height: 48px;
                border-radius: 24px;
            }
            &-desc{
                margin-left: 20px;
                margin-top: 2px;
            }
            &-name{
                text-decoration: underline;
                font-size: 16px;
            }
        }
        .description{
            margin-top: 20px;
        }
        .button{
            margin-top: 30px;
        }
    }
    
}


// > 1440
@mixin mod-banner-boxed-desktop(){
    .banner-hero-boxed{
        width: 1200px;
        .objects{
            padding-left: 40px;
        }
    }
}

@mixin mod-banner-boxed-tablet-portrait() {
    .banner-hero-boxed{
        width: 708px;
        
        .image-mobile{
            display: none;
        }
        .image{
            height: 100%;
            display: block;
            object-fit: cover;
            max-width: 100%;
            z-index: 1;
            flex-shrink: 0;
            display: inline-block;
        }
        .objects{
            width: 360px;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 30px;
        }
        &.large{
            .col-mask{
                height: 600px;
            }
            height: 600px;
            .main-title{
                font-size: 32px;
            }
            .description{
                font-size: 20px;
            }
        }
        &.medium{
            .col-mask{
                height: 400px;
            }
            height: 400px;
            .main-title{
                font-size: 28px;
            }
            .description{
                font-size: 18px;
            }
        }
        .main-title{
            width: 318px;
            line-height: 1.19;
        }
        .description{
            margin-top: 10px;
            width: 318px;
            line-height: 1.3;
        }
        .agente{
            &-photo{
                width: 48px;
                height: 48px;
            }
            &-desc{
                margin-left: 10px;
                width: 260px;
            }

            &-name{
                font-size: 14px;
            }

            &-told {
                font-size: 14px;
                line-height: 20px;;
                margin-bottom: 2px;
            }
        }

        .button{
            margin-top: 20px;
        }
        
        a.alleanza-button {
            min-width: 168px;
            width: 198px;
        }
    }
    
}

@mixin mod-banner-boxed-mobile() {
    .banner-hero-boxed{
        max-width: 345px;
        width: 100%;
        height: auto !important;
        
        .image{
            display: none;
        }

        .objects{
            padding: 20px;
            height: auto !important;
        }
        &.large{
            .col-mask{
                height: 294px;
                
            }
            .image-mobile{
                display: block;
                width: 100%;
                height: 100%;
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
                object-fit: cover;
                z-index: 1;
                flex-shrink: 0;
            }
            .main-title{
                font-size: 32px;
            }
            .description{
                font-size: 20px;
            }
        }
        &.medium{
            .col-mask{
                height: 272px;

            }
            .image-mobile{
                display: block;
                width: 100%;
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
                object-fit: cover;
                height: 100%;
                z-index: 1;
                flex-shrink: 0;
                display: inline-block;
            }
            .main-title{
                font-size: 28px;
            }
            .description{
                font-size: 18px;
            }
        }
        
        .main-title{
            // max-width: 305px;
            width: 100%;
            line-height: 1.19;
        }
        .description{
            margin-top: 10px;
            // max-width: 305px;
            width: 100%;
            line-height: 1.3;
        }
        .button{
            margin-top: 20px;
        }

        a.alleanza-button {
            width: 198px;
        }

        .agente{
            &-photo{
                width: 32px;
                height: 32px;
            }
            &-desc{
                margin-left: 10px;
                // width: 263px;
            }

            &-name{
                font-size: 14px;
            }

            &-told {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 2px;
            }
        }
    }
}
  
@mixin colors-banner-boxed-xs(){
    &.blu-chiaro{
        background-color: $blue-chiaro;
        @include banner-boxed-text-color($white, $white, $white, $white, $light-grey-2);
    }
    &.bg-bianco{
        background-color: $white;
        @include banner-boxed-text-color($color-alleanza-blue, $color-alleanza-orange, #D15604, #b23600, #ff9741);
    }
    &.blu-scuro{
        background-color: $dark-blue;
        @include banner-boxed-text-color($white, $white, $white, $white, $light-grey-2);
    }
    &.bg-alleanza-blu{
        background-color :$color-alleanza-blue;
        @include banner-boxed-text-color($white, $color-alleanza-orange, #D15604, #b23600, #ff9741);
    }
    &.bg-alleanza-arancio{
        background-color: $color-alleanza-orange;
        @include banner-boxed-text-color($color-alleanza-blue, $color-alleanza-blue, #00002C, #000f20, #395082);
    }
    &.arancio-scuro{
        background-color: $dark-orange;
        @include banner-boxed-text-color($white, $white, $white, $white, $light-grey-2);
    }
    &.arancio-chiaro{
        background-color: $orange;
        @include banner-boxed-text-color($color-alleanza-blue, $color-alleanza-blue, #00002C, #000f20, #395082);
    }
    &.risparmio{
        background-color: $color-category-risparmio;
        @include banner-boxed-text-color($color-alleanza-blue, $color-alleanza-blue, #00002C, #000f20, #395082);
    }
    &.investimento{
        background-color: $color-category-investimento;
        @include banner-boxed-text-color($white, $white, $white, $white, $light-grey-2);
    }
    &.protezione{
        background-color: $color-category-protezione;
        @include banner-boxed-text-color($color-alleanza-blue, $color-alleanza-blue, #00002C, #000f20, #395082);
    }
    &.previdenza{
        background-color: $color-category-previdenza;
        @include banner-boxed-text-color($color-alleanza-blue, $color-alleanza-blue, #00002C, #000f20, #395082);
    }
}

@mixin banner-boxed-text-color($txtcolor, $linkcolor, $linkhover, $linkactive, $linkvisited){
    color: $txtcolor;
    a.link{
        color: $linkcolor;
        border-bottom: 1px solid $linkcolor;
        &:not([disabled]):hover{
            color: $linkhover;
        }
        &:not([disabled]):focus{
            border-bottom: 2px solid $linkcolor;
        }
        &:not([disabled]):active{
            border-bottom: 1px solid $linkactive;
            color: $linkactive;
        }
        &:not([disabled]):visited{
            border-bottom: 1px solid $linkvisited;
            color: $linkvisited;
        }
        &[disabled], &:not([href]) {
            color: $light-grey-2;
            pointer-events: none;
            cursor: not-allowed;
        }
    }
}