@import "../variablesFull";
@import "../../modules/banners/mod-banner-animated";
@import "../../modules/banners/mod-banner-boxed";
@import "../../modules/banners/mod-banner-cards";
@import "../../modules/banners/mod-banner-light";
@import "../../modules/banners/mod-card-bianca-banner";
@import "../../modules/banners/mod-hero-banner-round-levels-animated";
@import "../../modules/banners/mod-hero-banner-round";
@import "../../modules/banners/mod-hero-banner";
@import "../../modules/banners/mod-hero-banner-card";
@import "../../modules/banners/mod-banner-text";
@import "../../modules/banners/mod-banner-agenzie";


@include mod-hero-banner();
@include mod-hero-banner-card();
@include mod-hero-banner-round();
@include mod-hero-banner-round-levels-animated();
@include mod-banner-boxed();
@include mod-banner-animated();
@include mod-banner-light();
@include mod-banner-text();
@include mod-banner-cards();
@include mod-card-bianca-banner();
@include mod-banner-agenzie();

/* Desktop viewport - More than 1440 */
@media all and (min-width: $viewport-width-desktop + px) {
    @include mod-hero-banner-desktop();
    @include mod-hero-banner-round-desktop();
    @include mod-banner-light-desktop();
    @include mod-banner-boxed-desktop();
    @include mod-banner-cards-desktop();
    @include mod-card-bianca-banner-desktop();
    @include mod-banner-agenzie-desktop();
}

/*Large Tablet  Landscap viewport-1025/1439*/
@media all and (min-width: ($viewport-width-tablet-landscape-large + 1) + px) and (max-width: ($viewport-width-desktop - 1) + px) {
    @include mod-banner-animated-small-desktop();
}

/*Large Tablet  Landscap viewport-990/1024*/
@media all and (min-width: $viewport-width-tablet-landscape + px) and (max-width: ($viewport-width-tablet-landscape-large - 1) + px) {
    @include mod-banner-animated-tablet-landscape();
}

/* From Tablet Landscape to Tablet Portrait viewport - From 1440 to 990 */
@media all and (min-width: $viewport-width-tablet-portrait + px) and (max-width: ($viewport-width-tablet-landscape - 1) + px) {
    @include mod-hero-banner-tablet-landscape();
    @include mod-hero-banner-card-tablet-landscape();
    @include mod-hero-banner-round-tablet-landscape();
    @include mod-banner-text-tablet-landscape();
    @include mod-card-bianca-banner-tablet-landscape();
    @include mod-banner-agenzie-tablet-landscape();
}

/* From Tablet Portrait to Mobile viewport - From 990 to 768 */
@media all and (min-width: $viewport-width-mobile + px) and (max-width: ($viewport-width-tablet-portrait - 1) + px) {
    @include mod-hero-banner-tablet-portrait();
    @include mod-hero-banner-card-tablet-portrait();
    @include mod-hero-banner-round-tablet-portrait();
    @include mod-hero-banner-round-levels-animated-tablet-portrait();
    @include mod-banner-boxed-tablet-portrait();
    @include mod-banner-animated-tablet-portrait();
    @include mod-banner-light-tablet-portrait();
    @include mod-banner-cards-tablet-portrait();
    @include mod-card-bianca-banner-tablet();
    @include mod-banner-text-tablet-portrait();
    @include mod-banner-agenzie-tablet-portrait();
}

/* Mobile viewport - Less then 768 */
@media all and (max-width: ($viewport-width-mobile - 1) + px) {
    //banners
    @include mod-hero-banner-mobile();
    @include mod-hero-banner-card-mobile();
    @include mod-hero-banner-round-mobile();
    @include mod-hero-banner-round-levels-animated-mobile();
    @include mod-banner-boxed-mobile();
    @include mod-banner-animated-mobile();
    @include mod-banner-light-mobile();
    @include mod-banner-cards-mobile();
    @include mod-card-bianca-banner-mobile();
    @include mod-banner-text-mobile();
    @include mod-banner-agenzie-mobile();
}