@mixin mod-hero-banner-round-levels-animated() {
    .hero-banner-round.levels-animated{
        .box-round{
            overflow: hidden;
            width: 140%; 
            margin-left: -20%; 
            margin-right: -20%;
            position: relative;
            border-radius: 0 0 50% 50%;
            // height: 500px;
            padding-top: 18px;
            height: 518px;
            .image{
                display: block;
                height: 100%;
                object-fit: contain;
            }

            .image-wall{
                display: block;
                height: 500px;
                width: 100%;
                object-fit: contain;
            }

            .container-image{
                position: absolute;
                bottom: 20px;
                height: 474px;
                width: 474px;
                left: 50%;
                margin-left: -237px;
                border-radius: 50%;
                // box-shadow: inset 0px 0px 40px -20px rgba(0,0,0,0.4);
                // background-color: $color-category-previdenza;
                background: radial-gradient(circle at 45% 15%, $color-category-previdenza, rgba(0,0,0,0.15));
                // transition: transform 2s;
                // transform: scale(1);
                &:hover{
                    background: radial-gradient(circle at 25% 5%, $color-category-previdenza, rgba(0,0,0,0.15));
                    // transform: scale(1.03);
                }

                picture{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top:0;
                    left:0;
                    
                    &.overflow-hidden{
                        border-radius: 50%;
                    }

                    &.pos1{
                        z-index: 3;
                    }
                    &.pos2{
                        z-index: 2;
                    }
                    &.pos3{
                        z-index: 1;
                    }

                    .image{
                        margin: 0 !important;
                        width: 100% !important;
                    }
                }
            }
        }
        &.small{
            .box-round{
                // overflow: hidden;
                // height: 382px;
                .image{
                    height: 100%;                   
                }
            }
        }
    }
}


@mixin mod-hero-banner-round-levels-animated-tablet-portrait() {
    .hero-banner-round.levels-animated{
        .box-round{
            height: 474px;
            overflow: hidden;
            width: 140%; 
            margin-left: -20%; 
            margin-right: -20%;
            position: relative;
            border-radius: 0 0 50% 50%;
            .container-image{
                width: 400px;
                height: 400px;
                margin-left: -200px;
                bottom: 30px;
            }
        }
    }
}


@mixin mod-hero-banner-round-levels-animated-mobile() {
    .hero-banner-round.levels-animated{
        .box-round{
            height: 424px;
            padding-top: 0;
            .image{
                display: none;
            }
            .container-image{
                height: 320px;
                width: 320px;
                margin-left: -160px;
                bottom: 50px;
            }
            .image-mobile{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
//                 // width: 140%; 
//                 // margin-left: -20%; 
//                 // margin-right: -20%;
//                 // position: relative;
//                 // border-radius: 0 0 50% 50%;
            }
        }
    }
}