@mixin mod-banner-light() {

    .light-banner {
        height: auto;
        min-height: 500px;
        background-color: $white;

        &.mirror-light-banner{
            .banner-page{
                order: 1;
                padding-left: 30px;
                padding-right: 0;
            }
            .banner-photo{
                order: 2;
            }
        }
        
        .banner-contenitor{
            margin-right: 0;
            margin-left: 0;
            min-width: 100%;
            height: 100%;
            padding: 0px;
            /* right: 0; */
            width: auto;
            .row{
                max-width: 1440px;
                margin: 0 auto;
                height: 100%;
            }
        }

        .banner-photo {
            height: auto;
            min-height: 500px;
            padding: 0px; 

            .image {
                height: 100%;
                -ms-interpolation-mode: bicubic;
                // display: block;            
                object-fit: cover;
                position: absolute;
                width: 100%;
                z-index: 1;
                flex-shrink: 0;
                display:inline-block;
            }   
            
            .image-mobile{
                display: none;
            }
        }

        .banner-page {
            height: 100%;
            min-height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            padding-right: 30px;
            
            .banner-pagination {
                .banner-title {
                    font-size: 42px;
                    line-height: 1.14;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    font-weight: bold;
                    color: $color-alleanza-blue;
                    padding: 0;
                    margin-bottom: 10px;
                }

                .banner-description {
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.3;
                    letter-spacing: normal;
                    color: $dark-grey-1;
                    padding: 0;
                    margin-bottom: 20px;
                }

                .alleanza-button {
                    min-width: 180px;
                }
            }
        }
    }
}

@mixin mod-banner-light-desktop(){
    .light-banner{
        &.mirror-light-banner{
            .banner-page{
                padding-left: 120px;
                padding-right: 0;
            }
        }
        .banner-page{
            padding-right: 120px;
        }
    }
}

@mixin mod-banner-light-tablet-portrait() {

    .light-banner {

        .banner-pagination {
            .banner-title {
                font-size: 28px;
                line-height: 34px;
            }

            .banner-description {
                font-size: 18px;
                line-height: 24px;
            }

            .alleanza-button {
                min-width: 228px;
            }
        }
        
        .banner-photo {

            .image {
                object-fit: cover;
            }
        }
    }
}


@mixin mod-banner-light-mobile() {
    .light-banner {
        height: auto;
        &.mirror-light-banner{
            .banner-page{
                order: 2;
            }
            .banner-photo{
                order: 1;
            }
        }
        .banner-photo {
            height: 500px;
        }
        
        .banner-page {
            height: auto;
            min-height: auto;
            display: flex;
            align-items: flex-start;
            padding-left: 0px !important;
            padding-right: 0px !important;

            .banner-pagination {
                margin-top: 30px;
                margin-bottom: 30px; 
                padding-left: 16px;
                padding-right: 15px;
                text-align: center;

                .banner-title {
                    font-size: 28px;
                    line-height: 1.21;
                    // margin: 0 8.3333%; 
                }

                .banner-description {
                    font-size: 18px;
                    line-height: 1.31;
                    margin-top: 10px;
                }

                .alleanza-button {
                    min-width: 288px;
                }
            }
        }

        .image {
            display: none !important;
        }
    
        .image-mobile{
            height: 100%;
            display: block !important;
            width: 100%;
            z-index: 0;
            object-fit: cover;
        }
    }
}
