@mixin mod-banner-text() {
    .info-banner{
        position: relative;
        z-index:4;
        &.slidedown{
            min-height: 79px;
            height: auto;
            // height: 98px;
        }
        &.slideup {
            overflow: hidden;
            height: 0;
            min-height: 0;
            transition: height 0.5s, min-height 0.5s;
            padding: 0 !important;
        }
        &-text{
            padding-left: 105px;

            padding-top: 19px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            font-family: 'AlleanzaMuliRegular';
        }
        .link-close{
            position: absolute;
            right: 10px;
            display: flex;
            outline: 0;
            
            &:hover {
                text-decoration: none;
                color: #D15604;
            }
            
            &:active {
                color: $button-orange-hover;
                text-decoration: none;
            }
            
            &:visited {
                color: $link-orange;
                text-decoration: none;
            }
            
            &:focus {
                text-decoration: underline;
            }
        }

    }
    .banner-text {
        &.bg-blue-text{
        background-color: $blue-medium !important;
        color:$white;
        }
        height: auto !important;
        min-height: 200px;
        display: flex;

        .banner-container {
            margin-bottom: auto;
            position: relative;
            margin-top: auto;
            height: 100%;
            width: 100%;
            max-width: 100% !important;
            padding: 70px 120px;
            // margin: 0px;
            display: flex;
            align-items: center;

          
            .row{
                width: 100%;
                max-width: 100%;
                display: flex;
                align-items: center;

                h2 {
                    text-transform: uppercase;
                    color:$white;
                    
                }
            }
        }
    }
}

@mixin mod-banner-text-tablet-landscape() {
    .info-banner{
        &-text{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .banner-text {

        .banner-container {
            padding: 55px 30px;

        }
    }
}

@mixin mod-banner-text-tablet-portrait() {
    .info-banner{
        &-text{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .banner-text {
        .banner-container {
            padding: 64px 30px;

            .font-sans-display-4{
                font-size: 18px; 
                line-height: 24px;
            }
        }
    }
}


@mixin mod-banner-text-mobile() {
    .info-banner{
        &-text{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .banner-text {
        min-height: 152px;
        .banner-container {
            padding: 40px 15px;

            .font-sans-display-4{
                font-size: 18px; 
                line-height: 24px;
            }
        }
    }
}
