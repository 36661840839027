@mixin mod-hero-banner-round() {
    .hero-banner-round.small{
        .first-row{
            padding-top: 30px;
            padding-bottom: 26px;
            min-height: 238px !important;
            margin-bottom: -1px !important;
            .label{
                margin: 10px;
                font-size: 56px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.11;
            }
            .description{
                font-size: 42px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                margin-top: 0px;
                text-transform: uppercase;
            }
        }

        .box-round{
            overflow: hidden;
            height: 382px;
            .image{
                height: 382px;        
                margin: 0 auto;           
            }

            *::-ms-backdrop, .image {
                max-height: 500px;
                display: block;
                height: 500px;
                width: 100%;
                object-fit: contain;                    
                -ms-transform: translate(90%, 5%);;
            } 

            *::-ms-backdrop, .image-wall {
                max-height: 500px;
                display: block;
                height: 500px;
                width: 100%;
                object-fit: contain;
                
                -ms-transform: translate(106%, 5%);;
            } 
        }
        
        .round-row{
            height: 382px !important;
        }
    }
    .hero-banner-round{
        overflow: hidden;
        &.generic{
            .first-row.box-square{
                height: auto;
                min-height: 190px;
                .col-sticker{
                    padding-top: 30px;
                    padding-bottom: 40px;
                }
                .title{
                    margin-top: 0;
                    text-transform: uppercase;
                }
            }
            .round-row{
                height: 462px;
                .image{
                    height: 462px;
                }
            }
            &.blu-chiaro, &.blu-scuro{
                h1.title, h2.title, p.description, h3.description{
                    color: $white;
                }
            }
            h1.title, h2.title{
                font-family: 'AlleanzaSans', 'Arial', sans-serif;
                font-size: 42px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: center;
                color: $color-alleanza-blue;
            }
            
            p.description, h3.description{
                font-family: 'AlleanzaSans', 'Arial', sans-serif;
                font-size: 56px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.11;
                letter-spacing: normal;
                text-align: center;
                color: $color-alleanza-blue;
            }
        }
        video::-webkit-media-controls-fullscreen-button {
            display: none;
        }
        picture{
            width: 100%;
        }
        .banner-round-container{
            max-width: 100%;
            padding-bottom: 60px;
        }
        .first-row{
            margin-bottom: -1px !important;
            min-height: 300px;
            height: auto;
            display: block;
            .col-sticker{
                text-align: center;
                position:relative;
                margin: 0 auto;
            }
        }

        .box-round{
            overflow: hidden;
            width: 140%; 
            margin-left: -20%; 
            margin-right: -20%;
            position: relative;
            border-radius: 0 0 50% 50%;
            height: 500px;
            text-align: -webkit-center;
            .image{
                display: block;
                height: 500px;
                object-fit: contain;   
                margin: 0 auto;   
            }

            .image-wall{
                display: block;
                height: 500px;
                width: 100%;
                object-fit: contain;
            }

            *::-ms-backdrop, .image {
                display: block;
                height: 500px;
                width: 100%;
                object-fit: contain;             
            }

            .image-mobile{
                display: none;
            }
        }

        .description{
            margin-top: 10px;
            text-align: center;
        }

        h3.label.font-paragraph-2 {
            text-align: center;
        }
        .round-row{
            height: 500px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        
        .title{
            font-family: 'AlleanzaSerif';
            margin-top: 20px;
            justify-content: center;
            display: flex;
            font-size: 42px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            color: $white;
            .bold{
                color: $color-alleanza-blue;
            }
        }
        .col-sticker{
            z-index: 1;
            // margin: auto;
            padding-top: 15px;
            padding-bottom: 15px;
            .contenitore-sticker{
                text-align: -webkit-center;
                // padding-top: 30px;
                .sticker{
                    margin: auto;
                    margin-top: 15px;
                    place-content: center;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    background-color: $white;
                    text-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
                    font-weight: 500;
                    width: 150px;
                    height: 38px;
                    border-radius: 21px;
                    color: $color-alleanza-blue;
                    font-family: 'AlleanzaSerif';
                    font-size: 16px;
                    &.cat-previdenza{
                        border: solid 1px $color-category-previdenza;
                    }
                    &.cat-investimento{
                        border: solid 1px $color-category-investimento;
                    }
                    &.cat-risparmio{
                        border: solid 1px $color-category-risparmio;
                    }
                    &.cat-protezione{
                        border: solid 1px $color-category-protezione;
                    }
                }
            }
        }
    }
}

@mixin mod-hero-banner-round-desktop(){
    .hero-banner-round{
        &.generic{
            .round-row{
                height: 500px;
                .image{
                    height: 500px;
                }
            }

        }
    }
}

@mixin mod-hero-banner-round-tablet-landscape() {
    .hero-banner-round{

        .first-row.box-square {
            min-height: 338px;
            .col-md-8.col-sticker{ 
                    max-width: 100% !important;
                }
        }
        .box-round {
            overflow: hidden;
            width: 140%; 
            margin-left: -20%; 
            margin-right: -20%;
            position: relative;
            border-radius: 0 0 50% 50%;
            height: 500px;
            .image {
                display: block;
                height: 500px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                border-radius: 0 0 50% 50%;
                object-fit: contain;
            }

            .image-wall {
                display: block;
                height: 500px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                border-radius: 0 0 50% 50%;
                object-fit: contain;
            }
            

            *::-ms-backdrop, .image {
                display: block;
                height: 600px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                border-radius: 0 0 50% 50%;
                object-fit: contain;
                max-height: 600px !important;
                // min-height: 500px !important;
                height: auto;
                width: 80% !important;
                min-width: 80% !important;
                -ms-transform: translate(38%, 10%) !important;
            }

        

            *::-ms-backdrop, .image-wall {
                max-height: 400px;
                display: block;
                height: 500px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                border-radius: 0 0 20% 20% !important;
                object-fit: contain;
                max-height: 500px !important;
                height: auto;
                width: 48% !important;
                max-width: 48% !important;
                -ms-transform: translate(96%, 6%) !important;
            } 
        }
    }
}

@mixin mod-hero-banner-round-tablet-portrait() {
    .hero-banner-round.small{
        .first-row{
            min-height: 196px !important;
            .col-sticker{
                padding-top: 30px;
                padding-bottom: 42px;
                .description{
                    font-size: 32px;
                    line-height: 1.19;
                }
                .label{
                    margin-top: 10px;
                    font-size: 32px;
                    line-height: 1.19;
                }
            }
        }
        .box-round{
            overflow: hidden;
            height: 382px;
            .image{
                height: 382px;                   
            }

            *::-ms-backdrop, .image {
                max-height: 500px;
                display: block;
                height: 500px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                border-radius: 0 0 50% 50%;
                object-fit: contain;
                max-height: 500px;
                height: auto;
                width: 80%;
                max-width: 80%;
                -ms-transform: translate(38%, 5%);;
            } 

                
            *::-ms-backdrop, .image-wall {
                display: block;
                height: 550px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                object-fit: contain;
                max-height: 600px !important;
                // min-height: 474px !important;
                height: auto;
                width: 80%;
                max-width: 80%;
                -ms-transform: translate(37%, 0%);
            }
        }
    }
    .hero-banner-round{
        &.generic{
            .first-row.box-square{
                height: auto;
                min-height: 156px; //226px;
                .title, .description{
                    font-size: 32px;
                }
            }
            .round-row{
                height: 474px;
                .image{
                    height: 474px;
                }
            }

        }
        .first-row{
            min-height: 226px;
            text-align: -webkit-center;

            .contenitore-sticker{
                padding-top: 0px;
                .sticker{
                    width: 138px !important;
                    height: 32px !important;
                    margin-top: 5px;
                }
            }

        }
        .box-round{
            height: 474px;
            overflow: hidden;
            width: 140%; 
            margin-left: -20%; 
            margin-right: -20%;
            position: relative;
            border-radius: 0 0 50% 50%;
            .image{
                display: block;
                height: 474px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                object-fit: contain;
            }

            .image-wall{
                display: block;
                height: 474px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                object-fit: contain;
            }

            *::-ms-backdrop, .image {
                display: block;
                height: 500px;
                width: 140%; 
                margin-left: -20%; 
                margin-right: -20%;
                position: relative;
                object-fit: contain;
                max-height: 500px !important;
                // min-height: 474px !important;
                height: auto;
                width: 100%;
                max-width: 100%;
                -ms-transform: translate(20%, 10%);
            }
            *::-ms-backdrop, .image-mobile-wall {
                display: none;
            }
        }
        
        .round-row{
            height: 474px !important;
        }

        .title, .description{
            font-size: 32px;
            line-height: 1.19;
        }
    }
}

@mixin mod-hero-banner-round-mobile() {
    .hero-banner-round.small{
        .first-row{
            min-height: 186px !important;
            .col-sticker{
                padding-top: 30px;
                padding-bottom: 44px;
            }
            .description{
                font-size: 20px;
                line-height: 1.3;
            }
            .label{
                margin-top: 10px;
                font-size: 32px;
                line-height: 1.19;
            }
        }
        .box-round{
            height: 414px !important;
            width: 200%;
            margin-left: -50%;
            margin-right: -50%;
            .image{
                display: none;
            }

            .image-wall{
                display: none;
            }

            .image-mobile{
                height: 100%;
                width: 100%;
            }
        }
    }
    .hero-banner-round{
        &.generic{
            .first-row.box-square{
                min-height: 134px;//226px;
                .col-sticker{
                    padding-bottom: 30px;
                }
                .title{
                    font-size: 20px;
                }
                .description{
                    font-size: 32px;
                }
            }
            .round-row{
                height: 474px;
                .image-mobile{
                    height: 474px;
                    object-fit: cover !important;
                }
            }

        }
        // margin-bottom: 450px;
        .banner-round-container{
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        .first-row{
            min-height: 226px;

            .contenitore-sticker{
                padding-top: 0px;
                .sticker{
                    width: 138px;
                    height: 32px;
                    border-radius: 16px;
                    font-size: 16px;
                    margin-top: 5px;
                }
            }

        }
        .box-round{
            height: 474px;
            width: 180%;
            margin-left: -40%;
            margin-right: -40%;
            .image{
                display: none;
            }
            .image-mobile{
                display: block;
                // height: 424px;
                // width: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
                
            }

            .image-mobile-wall{
                display: block;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

            *::-ms-backdrop, .image-mobile {
                display: block;
                height: 424px;
                width: 100%;
                object-fit: contain;                
                -ms-transform: translate(0%, 18%);
            }
            
            *::-ms-backdrop, .image-mobile-wall {
                display: block;
                height: 500px;
                width: 100%;
                object-fit: contain;
                -ms-transform: translate(28%, 21%);
            }
        }

        .title{
            font-size: 20px;
            line-height: 1.3;
            .bold{
                font-weight: 900;
            }
        }
        .description{
            font-size: 32px;
            line-height: 1.19;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
}
  