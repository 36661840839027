@mixin mod-banner-cards() {
    .bg-small-cards{
        height: 585px !important;
    }
    .banner-head{
        height: 478px;
    }
    .row-banner-card {
        padding:32px 16px;
    }
    .banner-cards{
        width: 100%;
        height: 585px;
        padding: 39px 30px 40px;
        // mix-blend-mode: multiply;
        background-color: $blue-medium;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &-video{
            height: 505px;
            width: 100%;
            position: absolute;
            overflow-y: hidden;
            overflow-x: hidden;
            margin-top: -39px;
            left: 0;
            video{
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: 0;
                min-height: 100%;
                min-width: 100%;
            }
        }
        &-container{
            padding-right: 0;
            padding-left: 0;
            .title{
                margin-bottom: 60px;
            }
            .description{
                margin-bottom: 30px;
            }
        }
        &--card{
            // width: 100%;
            position: relative;
            cursor: pointer;
            width: 294px;
            height: 202px;
            object-fit: contain;
            border-radius: 8px;
            background-color: $white;
            margin-left: auto;
            margin-right: auto;
            &.state-active {
                box-shadow: $card-active-shadow;
            }
            &:hover{
                box-shadow: $shadow-hover;
            }
            &-image{
                img{
                    width: 100%;
                    border-radius: 4px;
                    height: 120px;
                }
            }
            .new{
                width: 72px;
                border-radius: 50%;
                height: 72px;
            }
            .label, .labelnew{
                align-self: center;
                width: 100%;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
            }
            .labelnew{
                display: table-cell;
                vertical-align: middle;
            }
            .discover-more{
                align-self: center;
                text-decoration: none;
                object-fit: contain;
                height: 32px;
                width: 100%;
                // padding-top: 5px;
                a{
                    i{
                        font-size: 32px;
                    }
                    float: right;
                    &:hover{
                        color: $color-alleanza-orange;
                        text-decoration: none;
                    }
                }
            }
            .discovernew{
                a{
                    float: none;
                }
            }
            .banner-cards--card-image{
                padding: 10px
            }
            .imagenew{
                padding: 0 !important;
                margin-right: 16px;
                max-width: 72px;
                width: 72px;
            }
            .banner-cards--card-arrow, .banner-cards--card-title{
                padding: 10px;
                // align-self: center;
                position: relative;
                display: flex;
                height: 52px;
                
            }
            .titlenew{
                padding: 0 !important;
                max-width: 148px !important;
                width: 148px !important;
                margin-right: 1px;
                height: 72px;
                display: table;
            }
            .arrownew{
                padding: 0 !important;
                margin-left: -3px;
                margin-top: 52px;
                height: 100%;
            }
        }
        .cardnew{
            height:136px;
        }
    }
    .slider-indicators{
        margin-bottom: 140px !important;
    }
    .carousel-hero-banner{
        height:585px;
        background-size: cover;
        margin-bottom:0;
    }
    .carousel-hero-opacity{
        background-image: linear-gradient(to bottom right, rgba(0,40,85,1), rgba(0,40,85,0));
        height:585px;
        width:100%;
        padding: 39px 30px 40px;
    }
    .carousel-control-both{
        z-index: 999999999 !important;
        width: 50px!important;
    }
    .carousel-arrow{
        font-size:30px;
    }
    .carousel-banner{
        padding:0 30px;
        margin-top:-69px;
        z-index:999999;
    }
    .carousel-card{
        z-index:999999;
    }
}

@mixin mod-banner-cards-desktop(){
    .row-banner-card {
        padding:16px;
    }
    .banner-cards{
        height: 585px;
        padding: 40px 120px 41px;
        &-video{
            height: 585px;
            margin-top: -40px;
        }
        &-container{
            .title{
                font-size: 56px;
                line-height: 1.11;
            }
            .description{
                font-size: 42px;
                line-height: 1.14;
            }
        }
        &--card{
            width: 384px;
            height: 242px;
            &-image{
                img{
                    height: 160px;
                }
            }
            .new{
                height: 104px;
                width: 104px;
                border-radius: 50%;
            }
            .label{
                font-size: 24px;
                line-height: 1.25;
            }
            .labelnew{
                font-size: 24px;
                display: table-cell;
                vertical-align: middle;
            }
            .discovernew{
                display: table-cell;
                vertical-align: middle;
            }
            .banner-cards--card-image{
                padding: 10px 10px 6px 10px;
            }
            .imagenew{
                padding: 0 !important;
                max-width: 104px;
                width: 104px;
            }
            .banner-cards--card-arrow, .banner-cards--card-title{
                padding: 0 10px 6px 10px;
                height: 60px;
            }
            .titlenew{
                padding: 0 0 0 16px;
                //padding-left: 16px;
                max-width: 200px !important;
                width: 200px !important;
                display: table;
                height: 104px !important;
            }
            .arrownew{
                padding: 0;
                margin-right: -14px;
                margin-left: 0;
                margin-top: 10px;
                display: table;
                height: 94px !important;
            }
        }
        .cardnew{
            height:136px;
        }
    }
    .slider-indicators{
        margin-bottom: 140px !important;
    }
    .carousel-hero-banner{
        height:585px;
        background-size: cover;
        margin-bottom:0;
    }
    .carousel-hero-opacity{
        background-image: linear-gradient(to bottom right, rgba(0,40,85,1), rgba(0,40,85,0));
        height:585px;
        width:100%;
        padding: 39px 30px 40px;
    }
    .carousel-control-both{
        z-index: 999999999 !important;
        width: 50px!important;
    }
    .carousel-arrow{
        font-size:30px;
    }
    .carousel-banner{
        padding:0;
        margin-top:-69px;
        z-index:999999;
    }
    .carousel-card{
        z-index:999999;
    }
}

@mixin mod-banner-cards-tablet-portrait() {
    .row-banner-card {
        padding:0 !important;
    }
    .row100 {
        width: 228px;
        max-width: 228px;
        clear: both;
        display: block;
    }
    .banner-head{
        height: 478px;
    }
    .banner-cards{
        height: 585px;
        padding: 39px 30px 37px;
        &-video{
            height: 504px;
        }
        &-container{
            .title{
                margin-bottom: 117px;
            }
            .description{
                margin-bottom: 15px;
            }
        }
        &--card{
            width: 228px;
            height: 162px;
            &-image{
                img{
                    height: 93px;
                }
            }
            .new{
                height: 72px;
                width: 72px;
                border-radius: 50%;
            }
            .label{
                font-size: 15px;
            }
            .labelnew{
                font-size: 16px;
            }
            .discover-more{
                a{
                    i{
                        font-size: 25px;
                    }
                }
            }
            
            .banner-cards--card-arrow, .banner-cards--card-title{
                height: 41px;
            }
            .arrownew{
                display: none;
            }
            .imagenew{
                width:72px;
                margin: 8px 78px;
            }
            .titlenew{
                width: 130px;
                margin: 0 49px 8px;
                text-align: center;
                height: 100%;
            }
        }
        .cardnew{
            height:136px;
        }
    }
    .carousel-hero-opacity{
        padding: 39px 30px 37px;
    }
    .carousel-desc{
        font-family: 'AlleanzaSans', 'Arial', sans-serif;
        font-size: 32px!important;
        line-height: 38px!important;
        font-weight: 700!important;
    }
}


@mixin mod-banner-cards-mobile() {
    .row-banner-card {
        padding: 8px 16px 0;
    }
    .banner-head{
        height: 497px;
    }
    .banner-cards{
        height: 585px;
        padding: 20px 15px 40px;
        &-video{
            height: 585px;
            margin-top: -20px;
        }
        &-container{
            .title{
                font-size: 28px;
                line-height: 1.21;
            }
            .description{
                margin-bottom: 20px;
                font-size: 24px;
                line-height: 1.25;
            }
        }
        &--card{
            height: 100px;
            // width: 345px;
            // max-width: 345px;
            width: 100%;
            margin-bottom: 20px;
            &-image{
                img{
                    height: 80px;
                    max-width: 120px;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .imagenew{
                text-align: center;
                margin: 0 auto !important; 
            }
            .new{
                height: 60px;
                    width: 60px;
                    max-width: 60px;
                    border-radius: 50%;
                    text-align: center;
            }
            .label{
                font-size: 20px;
            }
            .labelnew{
                font-size: 14px;
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }

            .titlenew{
                display: flex;
            }
            .discover-more{
                a{
                    i{
                        font-size: 24px;
                    }
                }
            }
            .discovernew{
                display: none;
            }
            .banner-cards--card-arrow, .banner-cards--card-title{
                padding: 10px;
                height: auto;
            }
        }
        .cardnew{
            height:136px;
        }
    }
    .slider-indicators{
        margin-bottom: 160px !important;
    }
    .carousel-hero-opacity{
        padding: 20px 15px 40px;
    }
    .carousel-desc{
        font-size: 24px!important;
        line-height: 1.25em!important;
    }
    .carousel-hero-banner{
        margin-top:55px;
    }
    .carousel-banner{
        padding:0 15px;
    }
}
