@mixin mod-hero-banner-card() {
  /*Start Module: Hero*/
  .hero {
    &.hero-banner-card {
      margin-bottom: 0;
    }
  }

  .hero-banner-card {
    .slide {
      .carousel-indicators {
        margin-bottom: 80px;
        li {
          &.active {
            background-color: $color-alleanza-orange;
          }
        }
      }
      .carousel-inner {
        .carousel-item {
          max-height: unset;
        }
      }
    }

    .hero-banner {
      height: unset;

      .banner-container {
        height: 680px;
      }

      .image,
      .image-mobile {
        height: 100%;
        object-fit: contain;
        object-position: 50% 100%;
      }

      .image-mobile {
        width: 100%;
      }
    }

    min-height: 585px;
    position: relative;
    background: $color-alleanza-blue;
    color: white;

    .banner-container {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    .row-banner {
      margin: 0 auto;
      max-width: 1200px;
    }

    .objects {
      padding-left: 0;
      margin: 0;
    }

    &__main-title {
      line-height: 62px;
      margin-top: 80px;
      margin-bottom: 16px;
    }

    &__background {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;

      & + .banner-container::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          100deg,
          rgba($color-alleanza-blue, 0.95),
          rgba($color-alleanza-blue, 0)
        );
      }
    }

    &__card-container {
      display: flex;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      gap: 24px;
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, 50%);
    }

    &__item {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      padding-right: 56px;
      background: white;
      box-shadow: 4px 4px 20px rgba(0, 15, 32, 0.1);
      border-radius: 8px;
      position: relative;
      transition: box-shadow 0.35s ease-in-out;

      &:hover {
        box-shadow: 4px 4px 24px rgba(0, 15, 32, 0.25);
        transition: box-shadow 0.25s ease-out;
      }
    }

    &__image {
      width: 104px;
      height: 104px;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
    }

    &__title,
    &__title:hover {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.25;
      color: $color-alleanza-blue;
      text-decoration: none;
      max-width: 15ch;
    }

    &__title::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &__icon {
      font-size: 32px;
      line-height: 32px;
      width: 32px;
      height: 32px;
      color: $color-alleanza-orange;
      position: absolute;
      right: 16px;
      padding-top: 0.1em;
    }

    & + section {
      .container {
        padding-top: 68px;
      }
    }

    & + .hero-banner,
    & + * .hero-banner {
      height: 668px;

      .banner-container {
        padding-top: 68px;
      }
    }
  }
  /*End Module: Hero*/
}

@mixin mod-hero-banner-card-tablet-landscape() {
  /*Start Module: Hero - Tablet Landscape*/

  .hero-banner-card {
    .objects {
      padding-left: 30px;
    }

    &__main-title {
      font-size: 48px;
      line-height: 56px;
    }

    &__card-container {
      padding: 0 30px;
    }

    &__item {
      padding: 32px 16px;
    }

    &__image {
      width: 72px;
      height: 72px;
    }

    &__title,
    &__title:hover {
      font-size: 20px;
    }

    &__icon {
      right: 8px;
      bottom: 8px;
      padding-top: 0.1em;
    }
  }
  /*End Module: Hero - Tablet Landscape*/
}

@mixin mod-hero-banner-card-tablet-portrait() {
  /*Start Module: Hero - Tablet Portrait*/

  .hero-banner-card {
    &__main-title {
      font-size: 36px;
      line-height: 1;
    }

    .objects {
      padding-left: 30px;
    }

    &__card-container {
      padding: 0 30px;
      gap: 12px;
    }

    &__item {
      padding: 16px;
      padding-top: 8px;
      flex-direction: column;
      gap: 8px;
    }

    &__image {
      width: 72px;
      height: 72px;
    }

    &__title,
    &__title:hover {
      font-size: 16px;
      text-align: center;
    }

    &__icon {
      display: none;
    }
  }
  /*End Module: Hero - Tablet Portrait*/
}

@mixin mod-hero-banner-card-mobile() {
  /*Start Module: Hero - Mobile*/

  .hero-banner-card {
    .objects {
      padding-left: 15px;
    }

    &__main-title {
      font-size: 28px;
      line-height: 32px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    &__card-container {
      padding: 0 15px;
      bottom: -16px;
      padding-bottom: 16px;
      gap: 6px;
      overflow-x: auto;
    }

    &__item {
      padding: 16px;
      padding-top: 8px;
      flex-direction: column;
      gap: 8px;
    }

    &__image {
      width: 48px;
      height: 48px;
    }

    &__title,
    &__title:hover {
      font-size: 14px;
      text-align: center;
    }

    &__icon {
      display: none;
    }
  }
  /*End Module: Hero - Mobile*/
}

@mixin mod-hero-banner-card-mobile-small() {
  .hero-banner-card {
    .objects {
      padding-left: 15px;
    }

    &__main-title {
      font-size: 28px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    &__card-container {
      padding: 0 15px;
      bottom: -16px;
      padding-bottom: 16px;
      overflow-x: auto;
    }
  }
}
