@mixin mod-hero-banner() {
  .hero-banner {
    overflow: hidden;
    height: 600px;
    align-self: center;
    .row-banner {
      max-width: 1200px;
      margin: 0 auto;
      height: 100%;
    }

    .col-mask {
      padding: 0;
    }

    .banner-container {
      margin-right: 0;
      margin-left: 0;
      min-width: 100%;
      height: 100%;
      padding: 0px;
      right: 0;
      width: auto;
    }
    .image-mobile {
      display: none;
    }
    .image-mobile-1 {
      display: none;
    }
    &.full-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .row {
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
      }
      &.white {
        color: $white;
        .agente-name {
          color: $white;
        }
      }
      &.blue {
        color: $color-alleanza-blue;
        .agente-name {
          color: $color-alleanza-blue;
        }
      }
    }
    &.secondario {
      height: 400px;
      .row-banner {
        height: auto;
        min-height: 400px;
      }
      .image {
        height: 400px;
        display: block;
        object-fit: cover;
      }
      .objects {
        -ms-transform: scale(0.85);
        -ms-transform-origin-y: 10px;
        margin-bottom: auto;
        position: relative;
        margin-top: auto;
        align-self: center;
        padding-left: 30px;
      }
      .main-title {
        margin-bottom: 10px !important;
      }
      .caption-title-1 {
        margin-bottom: 10px;
        font-weight: 600;
      }
      .objects-1 {
        padding-top: 40px;
        object-fit: cover;
        -ms-transform: scale(0.85);
        -ms-transform-origin-y: 10px;
        padding-left: 30px;
      }
    }
    .objects {
      -ms-transform: scale(0.85);
      -ms-transform-origin-y: 10px;
      margin-bottom: auto;
      position: relative;
      margin-top: auto;
      align-self: center;
      padding-left: 30px;
    }

    .image {
      display: block;
      position: absolute;
      object-fit: cover;
      height: 600px;
      width: 100%;
      z-index: 1;
      flex-shrink: 0;
      display: inline-block;
    }

    .caption-title {
      margin-bottom: 10px;
      font-weight: 600;
    }
    .main-title {
      margin-bottom: 20px;
      font-weight: 600;
    }
    .description {
      margin-bottom: 20px;
      font-weight: 500;
    }
    .agente {
      display: -webkit-box;
      display: flex;
      &-photo {
        background-size: cover;
        width: 48px;
        height: 48px;
        border-radius: 24px;
      }
      &-told {
        display: flex;
        font-family: "AlleanzaSerif";
        font-weight: 900;
      }
      &-desc {
        margin-left: 20px;
        align-self: center;
      }
      &-name {
        color: white;
        text-decoration: underline !important;
        font-size: 16px;
        align-self: center;
        padding-left: 5px;
      }
    }

    &__button-container {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap-reverse;
      column-gap: 20px;
      row-gap: 16px;

      .alleanza-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        max-width: 240px;
        white-space: nowrap;
        min-width: unset;
      }
    }
  }
}

@mixin mod-hero-banner-desktop() {
  .hero-banner {
    .objects {
      padding-left: 0;
    }
    &.secondario {
      .objects {
        padding-left: 0;
      }
      .objects-1 {
        padding-left: 0;
      }
    }
  }
}

@mixin mod-hero-banner-tablet-landscape() {
  .hero-banner {
    &.secondario {
      .objects {
        padding-top: 0px;
        padding-bottom: 0px;
        align-self: center !important;
      }
    }

    .image {
      z-index: 1;
      flex-shrink: 0;
    }

    .agente {
      .agente-desc {
        position: absolute;
        margin-left: 60px; //30px zeplin
      }

      .agente-told {
        line-height: 20px;
      }
    }
  }
}
@mixin mod-hero-banner-tablet-portrait() {
  .hero-banner {
    height: 600px;
    &.secondario {
      height: 400px;
      .font-caption-1 {
        font-size: 18px;
        line-height: 24px;
      }
      .caption-title-1 {
        margin-bottom: 4px;
      }
      .font-sans-display-2 {
        font-size: 28px;
        line-height: 34px;
      }
      .font-paragraph-2 {
        font-size: 18px;
        line-height: 24px;
      }
      .image {
        height: 400px;
        max-width: 100% !important;
        z-index: 1;
        flex-shrink: 0;
      }
      .objects {
        align-self: center;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      .objects-1 {
        padding-top: 40px !important;
        -ms-transform: scale(0.85);
        -ms-transform-origin-y: 10px;
      }
    }
    .image-mobile {
      display: none;
    }
    .image-mobile-1 {
      display: none;
    }

    .image {
      width: 100%;
      height: 600px;
      // object-fit: contain;
    }

    .caption-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 4px !important;
    }
    .main-title {
      font-size: 32px;
      line-height: 1.19;
      margin-bottom: 10px !important;
    }
    .description {
      font-size: 20px;
      line-height: 1.3;
    }

    .agente {
      &-photo {
        width: 32px;
        height: 32px;
      }
      &-told {
        margin-bottom: 2px;
        font-size: 14px;
      }
      &-desc {
        margin-left: 10px;
        width: 306px;
      }
      &-name {
        font-size: 14px;
      }
    }

    &__button-container {
      margin-top: 20px;
      column-gap: 8px;

      .alleanza-button {
        padding: 0 8px;
      }
    }
  }
}

@mixin mod-hero-banner-mobile() {
  .hero-banner {
    // height: 700px;
    height: auto !important;
    width: 100%;
    .row-banner .objects .button{
      margin-top:20px;
    }
    .image {
      display: none !important;
    }
    .banner-container {
      padding-left: 0px;
      padding-right: 0;
      width: 100%;
    }

    .objects {
      // min-height: 370px;
      height: auto;
      padding: 20px 15px 20px 15px;
    }

    &.full-image {
      height: 700px;
    }
    &.secondario {
      // height: 600px;
      .font-caption-1 {
        font-size: 18px;
        line-height: 24px;
      }
      .caption-title-1 {
        margin-top: 0px;
        margin-bottom: 4px;
      }
      .font-sans-display-2 {
        font-size: 28px;
        line-height: 34px;
      }
      .font-paragraph-2 {
        font-size: 18px;
        line-height: 24px;
      }
      &.full-image {
        height: 600px !important;
      }
      .objects,
      .objects-1 {
        padding: 20px 15px 20px 15px;
        align-self: center !important;
      }
      .image-mobile,
      .image-mobile-1 {
        height: 300px; //330px;
        display: block;
        width: 100%;
        z-index: 0;
        object-fit: cover;
      }
    }
    .col-mask {
      padding: 0;
      text-align: -webkit-center;
    }
    .image-mobile {
      height: 330px;
      display: block;
      // width: 100% !important;
      z-index: 0;
      object-fit: cover;
    }

    *::-ms-backdrop,
    .image-mobile {
      width: auto;
      height: 100%;
      transform: scaleY(1.1);
    }

    .caption-title {
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 1.33;
    }
    .main-title {
      font-size: 32px;
      line-height: 1.19;
      margin-bottom: 6px;
    }
    .description {
      font-size: 20px;
      margin-bottom: 0;
      line-height: 1.3;
    }
    .agente {
      margin-top: 20px;
      &-photo {
        width: 32px;
        height: 32px;
      }
      &-told {
        font-size: 14px;
        margin-bottom: 2px;
      }
      &-desc {
        margin-left: 10px;
        width: 303px;
      }
      &-name {
        font-size: 14px;
      }
    }

    &__button-container {
      margin-top: 20px;
      column-gap: 8px;

      .alleanza-button {
        padding: 0 8px;
      }
    }
  }
}
