@mixin mod-banner-animated() {

    .hero-banner.video-animated{

        .ovh{
            overflow-y: hidden;
        }

        .img-to-video-section{
            height: 100%;
            width: 100%;
            margin-right: 0px !important;
            position: relative;
            min-height: 420px;

            .video-section{
                display: flex;
                opacity: 0;
                width: 100%;
                height: 100%;
                align-items: center;
                position: absolute;
                video{
                    position: absolute;
                    right: 0px;
                    width: 100%;
                    height: auto;
                }
            }
            .images-section{
                position: absolute;
                width: 100%;
                height: 100%;
            }

        }
    }


    .banner-hero-boxed.bhb-animated{
        .banner-boxed{
            .row{
                overflow:hidden;
            }
        }
        .image, .image-mobile{
            margin-bottom: -10px;
        }
    }

}

@mixin mod-banner-animated-small-desktop(){
    .hero-banner.video-animated{

        .objects{
            padding-top: 109px !important;
            padding-bottom: 109px !important;
        }

        .img-to-video-section{
            .video-section{
                align-items: center;
                position: absolute;

                video{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@mixin mod-banner-animated-tablet-landscape() {

    .hero-banner.video-animated{

        .objects{
            padding-top: 63px !important;
            padding-bottom: 63px !important;
        }

        .img-to-video-section{
            .video-section{
                align-items: center;
                position: absolute;

                video{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
@mixin mod-banner-animated-tablet-portrait() {

    .hero-banner.video-animated{

        .objects{
            padding-top: 135px !important;
            padding-bottom: 131px !important;
            //padding-left: 0px !important;
        }

        .img-to-video-section{
            .video-section{
                align-items: center;
                position: absolute;
                video{
                    width: 100%;
                    height: auto;

                }
            }
        }
    }

}
@mixin mod-banner-animated-mobile() {
    .hero-banner.video-animated{

        // .objects{
        //     padding-top: 20px !important;
        //     padding-bottom: 20px !important;
        // }

        .img-to-video-section{
            height: 330px;
            min-height: auto;

            .images-section{
                .image-mobile{
                    min-height: auto;
                }
            }

            .video-section{
                align-items: end;
                video{
                    position: absolute;
                    // min-height: 423px;
                    width: 100%;
                    // height: auto;
                    height: 330px;
                }
            }
        }
    }

}
  